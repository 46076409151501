.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
}

/* CSS */
.button-9 {
    appearance: button;
    backface-visibility: hidden;
    background-color: #405cf5;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
        Ubuntu, sans-serif;
    font-size: 100%;
    height: 44px;
    margin: 12px 0 0;
    outline: none;
    overflow: hidden;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-9:disabled {
    cursor: not-allowed; /* Shows a 'not-allowed' cursor when the button is disabled */
}

.button-9:focus {
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
        rgba(50, 151, 211, 0.3) 0 0 0 4px;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(
        0,
        0,
        0,
        0.1
    ); /* Change the last value (alpha) for transparency */
}

.modal-content {
    background-color: white;
    width: 40%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
}

/* Add border to input fields */
.modal-content input[type="text"] {
    border: 1px solid #ccc; /* Specify border style */
    padding: 8px; /* Add padding for better appearance */
    border-radius: 4px; /* Add border radius for rounded corners */
    margin-bottom: 10px; /* Add margin between input fields */
    width: calc(100% - 20px); /* Calculate width to account for padding */
}

.closing {
    animation: modalCloseAnimation 0.3s forwards;
}

/* from hara */
.modal-btn-card {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.modal-btn-print {
    background-color: #405cf5;
    border: 1px solid #d9dee2;
    padding: 8px;
    border-radius: 7px;
    width: 100%;
    display: block;
    color: white;
}
.modal-btn-print:disabled {
    cursor: not-allowed; /* Shows a 'not-allowed' cursor when the button is disabled */
}
.modal-btn-print-ep {
    background-color: #405cf5;
    border: 1px solid #d9dee2;
    padding: 8px;
    border-radius: 7px;
    width: 100%;
    display: block;
    color: white;
}
.modal-btn-cancel {
    background-color: #eaeced;
    border: 1px solid #d9dee2;
    padding: 8px;
    border-radius: 7px;
    width: 100%;
    display: block;
}
.modal-btn-cancel:focus {
    box-shadow: rgba(217, 222, 226) 0 0 0 2px;
}
.content-left {
    text-align: left !important;
}
.line-height-sm{
    line-height: 15px;
}
.ep-print-modal-card {
    width: 30% !important;
}
.modal-btn-print-ep:disabled {
    cursor: not-allowed; /* Shows a 'not-allowed' cursor when the button is disabled */
}
.content-center {
    margin: auto;
    width: 50%;
    padding: 10px;
}

@keyframes modalCloseAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
