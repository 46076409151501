body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* view info modal */
@media screen and (max-width: 1000px){ 
  .modal-viewInfo {
    width: 90% !important;
    height: 90% !important;
  }

  .modal-cardEdit {
    width: 90% !important;
    overflow: scroll;
    height: 100%;
    display: block;
  }

  .cardFooterItem {
    display: block !important;
    width: 100% !important;
  }

  .cardFooterCenter {
    text-align: center;
  }
}

.userModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 90%;
  border-radius: 10px;
}

.text-middle {
  vertical-align: middle !important;
}

.width-100-require {
  background-color: red;
  margin: 2px;
}
.bg-red-sample {
  background-color: red !important;
}
