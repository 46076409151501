@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body{
	font-family: 'Inter', sans-serif;
}

.bg-white {
	background-color: whitesmoke;
}

.bg-red {
	background-color: red;
}

.c{
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: -1;
}

/* .bg-bluelines {
	background-image: url(../dist/img/bg-bluelines.jpg);
	background-repeat: no-repeat;
	background-size: cover;
} */

.login-container{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
    padding: 0;
	background-color: #f0f0f0;
	/* background-image: url(../dist/img/bg-bluelines.jpg); */
	background-repeat: no-repeat;
	background-size: cover;
}

.login-bg{
	color: #ffffff;
	align-items: center;
	padding: 5%;
	margin: auto;
	width: 100%;
}

.login-content{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}

.login-w-500{
	width: 500px;
}

.login-form-card {
	background-color: white;
	margin: auto;
	border-radius: 10px;
	box-shadow: -1.928px 2.298px 6.4px 1.6px rgba(40, 40, 40, 0.05);

}
  
.login-form{
	width: 360px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.company-logo{
    height: 50px;
}

.login-title{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 1.3rem;
}

.input-div{
	position: relative;
    display: grid;
    grid-template-columns: 8% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border: 2px solid #d9d9d9;
	border-radius: 7px;
}

.input-div.focus{
	border: solid 2px #3354cc;
}

.login-content .input-div .one{
	margin-top: 0;
}

.i-icon{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon-credential{
	transition: .3s;
}

.input-div-group{
    position: relative;
	height: 45px;
}

.text-credential{
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 17px;
	transition: .3s;
	font-weight: normal;
}

/* .input-div:before, .input-div:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 100%;
	transition: .4s;
	border: 2px solid red; 
}  */

.input-div:before{
	right: 50%;
}

.input-div:after{
	left: 50%;
}

.input-div.focus:before, .input-div.focus:after{
	width: 50%;
}

.input-div.focus > .input-div-group > .text-credential{
	top: -6px;
	left: 5px;
	font-size: 14px;
	background-color: #fff;
	padding-left: 5px;
	padding-right: 5px;
	color: #3354cc;
}


.input-div > .input-div-group > .login-input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	
}

.input-div.pass{
	margin-bottom: 4px;
}

.a-link{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}

.a-link:hover{
	color: #3354cc;
}

.login-btn{
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 7px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #3354cc, #3354cc, #4164e3);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}
.login-btn:hover{
	 
}


@media screen and (max-width: 1050px){
	.login-container{
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px){
	.login-form{
		width: 290px;
	}

	.login-title{
        font-size: 1rem;
        margin: 8px 0;
	}

	.login-bg{
		width: 400px;
	}
}

@media screen and (max-width: 900px){
	.login-container{
		grid-template-columns: 1fr;
	}

	.login-bg{
		display: none;
	}

	.login-base-bg{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}