.App {
    text-align: center;
    padding-top: 10%;
    padding-left: 40%;
    width: 40%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.upload-pdf {
    height: 60px;
}

.button-8 {
    appearance: button;
    background-color: #405cf5;
    border-radius: 6px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.1) 0 2px 5px, rgba(0, 0, 0, 0.07) 0 1px 1px;
    color: #fff;
    cursor: pointer;
    font: 100%/1.15 -apple-system, system-ui, "Segoe UI", Roboto,
        "Helvetica Neue", Ubuntu, sans-serif;
    height: 44px;
    width: 270px !important;
    margin-top: 12px;
    outline: none;
    /* overflow: hidden; */
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
}
.button-9 {
    appearance: button;
    background-color: #405cf5;
    border-radius: 6px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.1) 0 2px 5px, rgba(0, 0, 0, 0.07) 0 1px 1px;
    color: #fff;
    cursor: pointer;
    font: 100%/1.15 -apple-system, system-ui, "Segoe UI", Roboto,
        "Helvetica Neue", Ubuntu, sans-serif;
    height: 44px;
    margin-top: 12px;
    outline: none;
    overflow: hidden;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    width: 90%;
}

.button-9:disabled {
    cursor: default;
}

.button-9:focus {
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
        rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/* CSS */

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
